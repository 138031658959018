@import 'styles/breakpoints';

.gridContainer {
    --gridContainer-padding: 2.2rem;
    display: grid;
    grid-template-columns: var(--gridContainer-columns, repeat(4, 1fr));
    padding: 0 var(--gridContainer-padding);
    grid-column-gap: var(--gridContainer-gap, 1.2rem);
    width: 100%;
}

@include medium {
    .gridContainer {
        --gridContainer-columns: repeat(8, 1fr);
        --gridContainer-padding: 4.8rem;
        --gridContainer-gap: 2.4rem;
    }
}

@include large {
    .gridContainer {
        --gridContainer-columns: repeat(12, 1fr);
        --gridContainer-padding: 6rem;
        --gridContainer-gap: 3.2rem;
    }
}

@include xLarge {
    .gridContainer {
        --gridContainer-padding: 9.6rem;
    }
}

@include xxLarge {
    .gridContainer {
        --gridContainer-columns: repeat(12, 1fr);
        --gridContainer-gap: 4.8rem;
    }
}
