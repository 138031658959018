.root {
    position: absolute;
    top: 0;
    width: 2.4rem;
    padding: 1.3rem 0;
    height: 100%;
    opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lines {
    position: absolute;
    top: 0;
    width: 2.4rem;
    padding: 1.3rem 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.line {
    background-color: var(--colors-cream);
    width: 1.2rem;
    height: 0.2rem;

    &:first-child,
    &:last-child {
        width: 2.4rem;
    }
}

.lineVertical {
    background-color: var(--colors-cream);
    width: 0.2rem;
    height: 100%;
}
