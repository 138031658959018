@import 'styles/mixins';
@import 'styles/animations';
@import 'styles/breakpoints';

.ingredients {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 2.4rem
        calc(var(--block-padding-end) * 1.5);
    color: var(--colors-blue);

    &.theme--makers-reserve {
        color: var(--colors-makersReserveBlack);
    }
}

.heading {
    text-transform: uppercase;
}

.list {
    max-width: 81.6rem;
    margin: 0;
    text-align: center;
}

.toolTip {
    cursor: help;
    text-decoration: underline;

    &:hover,
    &:active {
        color: var(--colors-orange);

        .theme--makers-reserve & {
            color: var(--colors-makersReserveGold);
        }
    }
}

.ingredientBubble {
    position: fixed;
    z-index: var(--z-modal);
    top: 15rem;
    display: flex;
    width: 23.6rem;
    height: 23.6rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    border-radius: 50%;
    background-color: var(--colors-blue);
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 20%);
    color: var(--colors-white);
    opacity: 0;
    pointer-events: none;
    text-align: center;
    transform: scale(0.8);
    transition: transform $fast $easeInOutCubic, opacity $fast $easeInOutCubic;

    .theme--makers-reserve & {
        background-color: var(--colors-makersReserveGold);
    }

    &--open {
        opacity: 1;
        transform: scale(1);
        transition: transform $fast $easeInOutCubic $fast,
            opacity $fast $easeInOutCubic $fast;
    }

    @media (hover: hover) and (pointer: fine) {
        top: var(--mouse-y, 0);
        left: var(--mouse-x, 0);
    }
}

.bubbleTitle {
    margin-bottom: 2rem;
    text-transform: uppercase;
}

@include large {
    .ingredients {
        padding-top: 4.8rem;
    }
}
