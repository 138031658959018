@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inner {
    --gridColumnAreas: 'a';

    display: grid;
    overflow: hidden;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    padding: 0 2.4rem var(--block-padding-end);
    margin: 0;
    grid-auto-columns: 1fr;
    grid-gap: 1.2rem;
    grid-template-areas: var(--gridColumnAreas);
    list-style: none;
}

.listItem {
    box-sizing: border-box;

    &:last-child {
        background: rgba(255, 255, 255, 50%);
    }
}

.landscape {
    width: 100%;
    margin: 1.2rem 0 0;
}

@include medium {
    .inner {
        --gridColumnAreas: 'a a';

        padding: 0 var(--block-padding-side) var(--block-padding-side);
    }

    @include last-filler-item(2);
}

@include large {
    .inner {
        --gridColumnAreas: 'a a a';
    }

    @include last-filler-item(3);
}
