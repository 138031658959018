@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
    padding: 113% 3.6rem 3.6rem 3.6rem;
    background-color: var(--colors-black);
    text-align: center;
}

.inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    picture {
        position: relative;
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.copy {
    max-width: 28.3rem;
    margin-top: 1.4rem;
    color: var(--textColor);

    .theme--makers-reserve & {
        color: var(--colors-makersReserveOffBlack);
    }
}

@media (min-width: 650px) {
    .inner {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 50%;
        justify-content: center;
    }

    .copy {
        max-width: 32.3rem;
    }
}

@include large {
    .root {
        overflow: hidden;
        min-height: 62.4rem;
        padding: 50% 0 0 0;
    }

    .inner {
        position: absolute;
        top: 0;
        right: 0;
        left: auto;
        width: 50%;
        height: 100%;
        justify-content: center;
        padding: 9%;
        padding-top: 0%;
    }

    .image {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .copy {
        max-width: 36rem;
        margin-bottom: 3.2rem;
    }
}
