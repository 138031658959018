@use 'sass:math';
@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/fonts';

.graph {
    position: relative;
    overflow: hidden;

    svg {
        width: 100%;
    }
}

.graphInner {
    position: relative;
    margin: 1.4% 10%;
}

.labelsPadding {
    padding: 0.2rem;
}

.numbers {
    fill: var(--colors-cream);
    font-family: $GTWalsheim;
    font-size: 14px;
    font-size-adjust: 0.5;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;

    .isDark & {
        fill: var(--colors-blueDark);
    }

    .theme--makers-reserve & {
        fill: var(--colors-makersReserveGoldAlt);
    }
}

.label {
    position: absolute;
    display: flex;
    width: 20rem;

    /* Main / Tillamook Cream */
    align-items: center;
    color: var(--colors-cream);
    font-family: $GTWalsheimCond;
    font-size: 8px;
    font-style: normal;
    font-weight: bold;

    /* identical to box height, or 10px */

    letter-spacing: 0.773346px;
    line-height: 120%;
    text-transform: uppercase;

    .labelInner {
        position: relative;
        width: 100%;
        margin-top: -0.5rem;
        margin-left: -10rem;
        transition: opacity 0.6s;
    }

    &.middle {
        text-align: center;
    }

    &.start {
        margin-left: 10rem;
        text-align: left;
    }

    &.end {
        margin-left: -10rem;
        text-align: right;
    }

    .hasInViewAnimation & {
        opacity: 0;
        transition: opacity $easeOutCubic $default;
    }

    .hasInViewAnimation.animateIn & {
        opacity: 1;
        transition-delay: calc(
            var(--label-transition-delay) * #{math.div($fast, 8)}
        );
    }

    .isDark & {
        color: var(--colors-blueDark);
    }
}

.circleGroup {
    .hasInViewAnimation & {
        opacity: 0;
        transform: scale(0.7);
        transition: opacity $easeOutCubic $default, transform $bounce $default;
    }

    .hasInViewAnimation.animateIn & {
        opacity: 1;
        transform: scale(1);
        transition-delay: calc(
            var(--circle-transition-delay) * #{math.div($fast, 3)}
        );
    }
}

.circle {
    opacity: 0.4;
    stroke: var(--colors-blue);
    stroke-width: 0.1rem;

    .theme--makers-reserve & {
        opacity: 1;
        stroke: var(--colors-makersReserveGoldAlt);
    }
}

.chartPolyline {
    stroke: var(--colors-cream);
    stroke-width: 0.3rem;

    .isDark & {
        stroke: var(--colors-blueDark);
    }
}

.points {
    transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.chartPoly {
    background: rgba(255, 252, 230, 80%);
    background-blend-mode: normal;
}

.chartPolyGroup {
    .hasInViewAnimation & {
        opacity: 0;
        transform: scale(0.7);
        transition: opacity $easeOutCubic $default, transform $bounce $default;
    }

    .hasInViewAnimation.animateIn & {
        opacity: 1;
        transform: scale(1);
        transition-delay: 0.35s;
    }
}

@include xLarge {
    .label {
        font-size: 12px;

        .labelInner {
            margin-top: -0.7rem;
        }
    }

    .circle {
        stroke-width: 0.2rem;
    }

    .numbers {
        font-size: 12px;
    }
}
