@import 'styles/breakpoints';
@import 'styles/mixins';

.blockGrid {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--block-padding-end);
    background-color: var(--background-color);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.flushBottomMobile {
        padding-bottom: 0;
    }

    padding-bottom: var(--block-padding-end);

    &.fullWidth {
        padding-left: 0;
        padding-right: 0;
    }
}

.blockGridInner {
    width: 100%;
    max-width: var(--block-max-width);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: var(--blockGrid-flex-direction, column);

    .roundedTop & {
        @include rounded-corners(
            $breakpoint: medium,
            $overflow: hidden,
            $radius: 2rem 2rem 0 0
        );
    }

    .roundedBottom & {
        @include rounded-corners(
            $breakpoint: medium,
            $overflow: hidden,
            $radius: 0 0 2rem 2rem
        );
    }

    .roundedTop.roundedBottom & {
        @include rounded-corners($breakpoint: medium, $overflow: hidden);
    }
}

.ctaCardWrapper {
    width: 100%;
    position: relative;

    > div {
        border-radius: initial;
    }
}

@include medium {
    .blockGrid {
        padding: var(--block-padding);
        padding-bottom: var(--block-padding-end);
    }

    .gridRow {
        --blockGrid-flex-direction: row;
    }
}
