@import 'styles/breakpoints';

.gridRow {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: var(--gridRow-flex-direction, column);
    color: var(--colors-blue);
}

.column {
    position: relative;
    width: var(--column-width, 100%);

    &:last-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(
            --column-lastChild-padding,
            var(--block-padding-end) 2.4rem
        );
        background-color: var(--colors-white);
    }

    &.blueBackground,
    .themeBlueBackground & {
        background-color: var(--colors-blue);
        color: var(--colors-cream);
    }

    .themeCreamBackground & {
        background-color: var(--colors-cream);
    }
}

.image {
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.lockup {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.eyebrow {
    margin-bottom: 1.2rem;
    text-transform: uppercase;
}

.headline {
    max-width: 35rem;
    order: 2;
    margin: var(--copy-margin, 0 0 2rem);
    text-align: center;

    &.capitalize {
        text-transform: uppercase;
    }

    span {
        display: block;
    }
}

.countUpHeadline {
    display: flex;
}

.listHeadline {
    margin-bottom: var(--listHeadline-margin-bottom, 2rem);
}

.listItem {
    p {
        margin-bottom: 0;
    }
}

.copy {
    max-width: 43.2rem;
    order: 3;
    text-align: center;
}

.iconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    order: 1;
    padding: var(--iconWrapper-padding, 2rem 0 2rem 0);
    padding-top: 0;

    .icon {
        position: relative;
        width: var(--icon-width, 6rem);
        height: var(--icon-height, 6rem);
        color: inherit;
        fill: inherit;
    }

    .creamIcon {
        color: var(--colors-cream);
    }

    // special override (non-standard icon)
    &.people {
        --icon-width: 7.1rem;
        --icon-height: 5.6rem;
    }
}

.buttonWrapper {
    position: relative;
    margin-top: var(--buttonWrapper-margin-top, 3.5rem);
}

.richTextH5 {
    margin: 0 0 1rem;
}

@include medium {
    .gridRow {
        --gridRow-flex-direction: row-reverse;
        --column-width: 50%;
        --iconWrapper-margin: 3rem 0 0 0;
        --iconWrapper-first-margin: 0 0 4rem 0;
        --column-lastChild-padding: var(--block-padding-end) 2.4rem;
        --copy-margin: 0 0 3rem;
        --listHeadline-margin-bottom: 3rem;
        --iconWrapper-padding: 3rem 0 3rem 0;
        --icon-width: 8rem;
        --icon-height: 8rem;

        .people {
            --icon-width: 9.4rem;
            --icon-height: 7.5rem;
        }
    }

    .gridRowReversed {
        --gridRow-flex-direction: row;
    }

    .buttonWrapper {
        --buttonWrapper-margin-top: 4.5rem;
    }
}

.testimonialCta {
    margin-top: 4.5rem;
}

@include large {
    .gridRow {
        --icon-width: 8rem;
        --icon-height: 8rem;
    }
}
