@import 'styles/breakpoints.scss';
@import 'styles/colors.scss';

.root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    width: 12rem;

    padding-left: 0;
    margin-right: 4rem;
}

.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    padding: 0;
}

.checkbox {
    width: auto;
    margin: 1rem 0;

    cursor: pointer;
    user-select: none;
    color: var(--colors-cream);
}

.input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.input:checked ~ .checkmark {
    background-color: var(--colors-cream);

    &:after {
        display: block;
    }
}

.checkmarkWrapper {
    position: relative;

    width: 2.2rem;
    height: 2.2rem;

    margin-right: 1.2rem;
}

.checkmark {
    display: block;

    position: absolute;
    top: 0;
    left: 0;

    width: 2.2rem;
    height: 2.2rem;

    background-color: var(--colors-white);
    border: 2px solid var(--colors-cream);
    border-radius: 2px;

    &:after {
        content: '';
        position: absolute;
        display: none;
    }
}

.checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid var(--colors-blue);
    border-width: 0 2px 2px 0;
    border-radius: 2px;
    transform: rotate(45deg);
}

@include medium {
    .root {
        justify-content: flex-end;

        width: auto;

        padding-left: 2.2rem;
        margin-right: 0;
    }
}
