@import 'styles/breakpoints';

.newsBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);
    color: var(--colors-blue);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.eyebrowBlock {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--block-padding-end);
}

.videoBlock {
    width: 100%;
    max-width: var(--block-max-width);
    margin-bottom: var(--videoBlock-margin-bottom, 1.9rem);
}

.storyBlock {
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    justify-content: center;
}

@include medium {
    .newsBlock {
        --videoBlock-margin-bottom: 4.8rem;
    }
}
