@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 70% 3.6rem 3.6rem 3.6rem;
    background-color: #000;
    text-align: center;
}

.inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    picture {
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
}

.copy {
    max-width: 28.3rem;
    margin-top: 1.4rem;
    color: var(--textColor);
}

.unorderedList {
    width: 100%;
    max-width: 28.3rem;
    padding-left: 0;
    margin-top: 0;
    color: var(--textColor);
    list-style: none;
    text-align: left;

    li {
        padding-left: 0;
        border-bottom: 1px solid var(--textColor);
        margin: 0;

        p {
            margin: 1rem 0;
        }
    }

    .theme--makers-reserve & {
        padding-left: 2rem;
        list-style-image: url('/images/icons/makers-reserve-list-bullet.svg');

        li {
            padding-left: 1rem;
            border-bottom: none;
            margin: 1rem 0;

            p {
                max-width: 38.6rem;
                padding-bottom: 1rem;
                margin: 0;
                font-size: 1.6rem;
            }

            &:after {
                position: relative;
                left: -3rem;
                display: block;
                width: calc(100% + 3rem);
                border-bottom: 2px solid var(--colors-makersReserveGold);
                content: '';
            }
        }
    }
}

@include large {
    .root {
        min-height: 62.4rem;

        justify-content: flex-end;
        padding: 50% 0 0 0;
    }

    .inner {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        justify-content: center;
        padding: 5.2rem 9%;
        padding-top: 0%;
    }

    .image {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .copy {
        max-width: 38rem;
        margin-bottom: 3.2rem;
    }

    .unorderedList {
        max-width: 38rem;
    }
}
