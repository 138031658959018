@import 'styles/animations';
@import 'styles/breakpoints';

.portalInner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
}

.root {
    width: 100%;
    position: fixed;
    top: 6rem;
    z-index: 10;
    transition: transform $fast $easeInOutCubic;

    &.isOverlay {
        top: 0.5rem;
        z-index: 99999;
    }

    &.headerHidden {
        transform: translateY(-6rem);
    }
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--colors-white);
    width: 5.4rem;
    height: 5.4rem;
    padding: 1.8rem;
    margin: 0.5rem 0.9rem 0 0;

    pointer-events: auto;
    z-index: 10;

    a,
    button {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
}

@include medium {
    .root {
        position: fixed;
        top: 8rem;

        width: 100%;

        &.headerHidden {
            transform: translateY(-7rem);
        }

        &.isOverlay {
            top: 3rem;
        }
    }
}

@include large {
    .root {
        width: 100%;
        max-width: var(--block-max-width-plus-padding);

        &.headerHidden {
            transform: translateY(-8rem);
        }
    }

    .inner {
        width: 100%;
        padding: 0 var(--block-padding-side);
        display: flex;
        justify-content: flex-end;
    }

    .close {
        position: relative;
        margin: 3rem -1.3rem 0 0;

        svg {
            width: 2.3rem;
            height: 2.3rem;
        }
    }
}
