@import 'styles/breakpoints';

.root {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
}

.inner {
    position: relative;
    width: 100%;
    color: var(--colors-blue);
    max-width: var(--block-max-width);
    display: var(--twoColumnText-display, block);
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .makersReserve & {
        color: var(--colors-cream);
    }
}

.column {
    width: var(--column-width, 100%);
    display: flex;
    flex-direction: column;
    align-items: var(--column-align-items, center);
    justify-content: center;
    text-align: var(--column-text-align, center);
    margin: var(--column-margin, var(--block-padding-end) 0);
    padding: var(--column-padding, 0 2.4rem);

    p {
        text-wrap: unset;
    }

    &:first-child {
        align-items: center;
        margin: var(--column-first-child-margin, 0 0 var(--block-padding-end));
        padding: var(
            --column-first-child-padding,
            var(--block-padding-end) var(--block-padding-side) 0
        );
    }

    .flushTop & {
        padding-top: 0;
    }

    .flushBottom & {
        padding-bottom: 0;
    }
}

.richEyebrow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;

    .makersReserve & {
        color: var(--colors-makersReserveGoldAlt);
    }
}

.heading {
    margin-bottom: 2rem;
}

.headline {
    margin-top: var(--headline-margin, 1.2rem);
    max-width: var(--headline-max-width, 48rem);

    .line {
        display: block;
    }

    .makersReserve & {
        text-transform: none;
        color: var(--colors-cream);
    }
}

.iconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: var(--iconWrapper-margin-top, 2rem);

    .icon {
        position: relative;
        width: var(--icon-width, 4.8rem);
        height: var(--icon-height, 1.1rem);
    }

    .makersReserve & {
        color: var(--colors-makersReserveGoldAlt);
    }
}

.buttonWrapper {
    position: relative;
    margin-top: var(--buttonWrapper-margin-top, 4.8rem);
    width: 100%;
    display: flex;
    justify-content: var(--buttonWrapper-justify-content, center);
}

@include medium {
    .inner {
        --twoColumnText-display: flex;
        --column-width: 50%;
        --column-text-align: left;
        --column-align-items: flex-start;
        --column-margin: 0;
        --column-padding: var(--block-padding-end) var(--block-padding-side)
            var(--block-padding-end) 4.8rem;
        --column-first-child-margin: 0;
        --column-first-child-padding: var(--block-padding-end) 4.6rem
            var(--block-padding-end) var(--block-padding-side);
        --headline-margin: 2.2rem;
        --headline-max-width: none;
        --iconWrapper-margin-top: 2.8rem;
        --icon-width: 6rem;
        --icon-height: 1.3rem;

        flex-direction: row;

        --buttonWrapper-justify-content: flex-start;
        --buttonWrapper-margin-top: 3.2rem;
    }

    .column {
        &:first-child {
            .ultrabold & {
                align-self: flex-start;
                transform: translateY(1rem);
            }
        }
    }
}

@include xxLarge {
    .inner {
        --column-padding: 9.6rem var(--block-padding-side) 9.6rem 2.4rem;
        --column-first-child-padding: 9.6rem 4.8rem 9.6rem
            var(--block-padding-side);
    }
}
