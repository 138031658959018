.root {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colors-cheddarCheeseCurdsSpecial);
    pointer-events: none;

    &.theme--makers-reserve {
        background-color: var(--colors-makersReserveOffBlack);
    }
}
