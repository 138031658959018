@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/mixins';

.productList {
    --gridColumnAreas: 'a a';

    display: grid;
    overflow: hidden;
    width: 100%;
    padding: 0 var(--productList-padding, 1rem) var(--block-padding-end);
    margin: 0;
    grid-auto-columns: 1fr;
    grid-gap: var(--gridGap, 1rem);
    grid-template-areas: var(--gridColumnAreas);
    list-style: none;
}

.listItem {
    box-sizing: border-box;

    &:last-child {
        background: rgba(255, 255, 255, 50%);
    }

    @include rounded-corners($overflow: hidden);
}

.landscape {
    width: 100%;
    margin: 1.2rem 0 0;
}

@include last-filler-item(2);

@include medium {
    .productList {
        --productList-padding: 2.4rem;
        --gridGap: 1.2rem;
    }

    .listItem {
        &:last-child {
            background: rgba(255, 255, 255, 50%);
        }
    }
}

@include large {
    .productList {
        --gridColumnAreas: 'a a a';
    }

    @include last-filler-item(3);
}

@include xxLarge {
    .productList {
        --gridColumnAreas: 'a a a a';
    }

    @include last-filler-item(4);
}
