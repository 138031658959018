@import 'styles/breakpoints';
@import 'styles/animations';

.paragraph {
    margin: 0;
    line-height: 0.813;
    width: 100%;
    text-align: center;
}

.word {
    overflow: hidden;
    width: 100%;
}

.underline {
    font-size: 1.5em;
    -webkit-text-stroke-width: 2px;
    -webkit-text-fill-color: transparent;
}

.wordInner {
    display: inline-block;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.4s $easeIn;

    .reveal & {
        transform: translate3d(0, 4%, 0);
        transition: transform 0.4s 0.4s $easeOut;
    }
}

.paragraph,
.word {
    float: left;

    &:not(:last-child) {
        margin-right: 0.25em;
    }
}

@include medium {
    .paragraph {
        display: block;
        text-align: left;
    }

    .word {
        display: inline-block;
    }

    .paragraph,
    .word {
        float: none;
    }
}
