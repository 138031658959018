@import 'styles/breakpoints';

.storyList {
    display: flex;
    flex-direction: var(--storyList-flex-direction, column);
    text-align: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

@include medium {
    .storyList {
        --storyList-flex-direction: row;
    }
}
