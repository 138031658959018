@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

.root {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &.isSlideshow {
        cursor: pointer;

        @media (hover: hover) {
            &:hover .image {
                transform: scale(1.02);
            }
        }
    }

    &.dark {
        --navigation-color: var(--colors-blue);
    }

    &.light {
        --navigation-color: var(--colors-white);
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: none;
    height: 100%;
    animation: fade-out 0.001s forwards;
    object-fit: cover;
    object-position: var(--vertical-alignment, center);
    pointer-events: auto;

    .isSlideshow & {
        transition: transform $slow $easeOutCubic;
    }

    &.isMounted {
        animation: fade-out 0.001s forwards $slow;
    }

    // ensure previous item is always underneath active item
    &.isMounted.isPrevious {
        z-index: 1;
    }

    // crossfade active item on top of previous item
    &.isMounted.isActive {
        z-index: 2;
        animation: fade-in $slow forwards;
    }
}

.nav {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.dots {
    position: relative;
    z-index: 3;
    height: 6rem;
    user-select: none;

    @include flex-center;
}

.dot {
    width: 1rem;
    height: 1rem;
    border: 0.2rem solid var(--navigation-color);
    border-radius: 50%;
    margin: 0.4rem;
    background-color: transparent;
    cursor: pointer;
    transition: background-color $easeOutCubic $superSlow;

    &.isActive {
        background-color: var(--navigation-color);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
