@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    width: 100%;
    max-width: var(--block-max-width);
    padding: 0;
    margin-bottom: 6.5rem; // +2rem taking the divoit into account
}

.helpWrapper {
    display: flex;
    width: 100%;
    height: 32.2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    background: var(--colors-white);
}

.headline {
    padding: 0 3.9rem;
    margin-bottom: 4rem;
    color: var(--colors-blue);
    font-size: 2.4rem;
    text-align: center;
}

@include medium {
    .root {
        width: 100%;
        max-width: var(--block-max-width);
        margin-bottom: 11.6rem; // +2rem taking the divoit into account
    }

    .headline {
        padding: unset;
        margin-bottom: 4rem;
        color: var(--colors-blue);
        font-size: 3.6rem;
    }
}
