@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    color: var(--colors-blue);
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding-end) 2.4rem;

    .flushTop & {
        padding-top: 0;
    }

    .flushBottom & {
        padding-bottom: 0;
    }
}

.media {
    position: relative;
    width: 100%;
    aspect-ratio: 3/2;
}

.accordion {
    position: relative;
    width: 100%;
}

@include medium {
    .inner {
        padding: var(--block-padding);
    }
}

@include large {
    .inner {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        .mediaOnLeft & {
            flex-direction: row;
        }
    }

    .media {
        width: 56.25%;
    }

    .accordion {
        width: 35%;
    }
}

@include xLarge {
    .media {
        width: calc(65% - 9.6rem);
    }
}
