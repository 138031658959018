@import 'styles/fonts';

.scrollIndicator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 22.4rem;
    height: 22.4rem;
    color: var(--colors-cream);
    cursor: pointer;
}

.textRing {
    position: absolute;

    .circle {
        fill: none;
        stroke: none;
    }

    .text {
        font-family: $GTWalsheimCond;
        font-weight: bold;
        font-size: 1.6rem;
        text-transform: uppercase;
    }
}

.arrow {
    position: relative;
    top: -20%;
    width: 6rem;
    height: 1.3rem;
    animation: bounce 0.5s infinite alternate ease-in;
}

@keyframes bounce {
    from {
        transform: rotate(90deg) translate3d(-0.5rem, 0, 0);
    }

    to {
        transform: rotate(90deg) translate3d(0.5rem, 0, 0);
    }
}
