@import 'styles/breakpoints';
@import 'styles/mixins';

.cta {
    position: relative;
    height: 100%;
    padding-top: 125%;
    background-color: var(--colors-blue);
    color: var(--colors-cream);

    @include rounded-corners;
}

.ctaInner {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
}

.iconWrapper {
    position: relative;
    width: 5rem;
    height: 5rem;
}

.headline {
    margin-bottom: 2rem;
}

.landscapeCTA {
    padding-top: 0;
}

.landscapeCTAInner {
    position: relative;
    padding: 10rem 0;
}
