.root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.border {
    position: absolute;
}

.borderTop {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.borderRight {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.borderBottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.borderLeft {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.corner {
    position: absolute;
    width: calc(50% - 2.5rem);
    height: calc(50% - 2.5rem);
    border-width: 0.1rem;
    border-style: solid;

    .borderWidth2px & {
        border-width: 0.2rem;
    }
}

.cornerTopLeft {
    top: 0;
    left: 0;
    border-bottom: 0;
    border-right: 0;
}

.cornerTopRight {
    top: 0;
    right: 0;
    border-bottom: 0;
    border-left: 0;
}

.cornerBottomLeft {
    bottom: 0;
    left: 0;
    border-top: 0;
    border-right: 0;
}

.cornerBottomRight {
    bottom: 0;
    right: 0;
    border-top: 0;
    border-left: 0;
}
