@import 'styles/breakpoints';
@import 'styles/mixins';

.textBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: var(--textBlock-padding, 3rem);
}

.storySubHeadline {
    margin: 0;
    text-transform: uppercase;
}

.storyHeadline {
    width: 100%;
    margin: var(--storyHeadline-margin, 1rem 0);
}

.aspectBox {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background-color: var(--colors-black);
    overflow: hidden;
}

.image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.link {
    margin: 0;
}

.listItem {
    margin: var(--listItem-margin, 0 0 2.4rem 0);
    background-color: var(--colors-white);
    color: var(--colors-blue);
    flex: var(--listItem-flex);

    @include rounded-corners($overflow: hidden);

    &:last-child {
        margin-right: 0;
    }
}

@include medium {
    .storyHeadlineWrapper {
        width: 100%;
    }

    .storyHeadline {
        width: 100%;

        --storyHeadline-margin: 1.6rem 0;
    }

    .listItem {
        --listItem-margin: 0 4.8rem 0 0;
        --listItem-flex: 1 50%;
    }
}
