@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.column {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
}

.imageOverflow {
    position: absolute;
    top: 4.8rem;
    left: 2.4rem;
    overflow: hidden;
    width: calc(100% - 4.8rem);
    height: calc(100% - 4.8rem);
    border-radius: 2rem;

    .isOverflow & {
        top: -4.8rem;
        height: calc(100% + 4.8rem);
    }
}

.imageWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: fade-out 0s $bounceLight forwards;
    opacity: 0;
    transform: scale(0.9);
    transform-origin: center bottom;

    .isBottomLeft & {
        transform-origin: left bottom;
    }

    .isBottomRight & {
        transform-origin: right bottom;
    }

    .isPrev & {
        animation: fade-out-scale-out 0.3s $bounceLight forwards;
    }

    .isActive & {
        animation: fade-in-scale-in 0.7s 0.1s $bounceLight forwards;
    }
}

.image {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    object-fit: contain;
    object-position: center bottom;

    .isBottomLeft & {
        object-position: left bottom;
    }

    .isBottomRight & {
        object-position: right bottom;
    }

    .isContained & {
        object-fit: cover;
    }

    .isOverflow & {
        height: calc(100% - 4.8rem);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-in-scale-in {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade-out-scale-out {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.1);
    }
}

@include smallOnly {
    // non overflow slides need to fade in and out in mobile only

    .imageWrapper {
        transform: scale(1);

        .isOverflow & {
            transform: scale(0.9);
        }

        .isPrev & {
            animation: fade-out 0.3s $easeOutCubic forwards;
        }

        .isActive & {
            animation: fade-in 0.7s 0.1s $easeOutCubic forwards;
        }

        .isOverflow.isPrev & {
            animation: fade-out-scale-out 0.3s $bounceLight forwards;
        }

        .isOverflow.isActive & {
            animation: fade-in-scale-in 0.7s 0.1s $bounceLight forwards;
        }
    }
}

@include medium {
    .column {
        aspect-ratio: 624 / 600;
    }

    .imageOverflow {
        width: 100%;

        .isContained & {
            top: 4.8rem;
            left: 4.8rem;
            width: calc(100% - 9.6rem);
            height: calc(100% - 9.6rem);
        }

        .isOverflow & {
            top: auto;
            bottom: 0;
            left: 0;
            height: calc(100% + (var(--block-padding-side) * 2));
            border-radius: 0;
            clip-path: inset(0 0 0 0 round 0 0 0 3rem);
        }
    }

    .image {
        .isOverflow & {
            height: calc(100% - var(--block-padding-side));
        }
    }
}
