@import 'styles/animations';
@import 'styles/breakpoints';

$bugSize: 5.7rem;

@include xxLarge {
    .root {
        position: absolute;
        width: $bugSize;
        height: $bugSize;
        top: 0;
        transition: transform $easeOutCubic $superSlow;

        svg {
            width: $bugSize;
            height: $bugSize;
        }
    }

    .inner {
        position: absolute;
        top: 0;
        transition: opacity $easeOutCubic $superSlow;
        opacity: 0;

        &.isActive {
            opacity: 1;
        }
    }
}
