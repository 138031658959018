@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navCount {
    width: 2.5rem;
    margin: 0 1.8rem;
    text-align: center;
}

.navItem {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navItemAnimation {
    position: absolute;
    width: 3.1rem;
    height: 3.1rem;
    opacity: 0;
    transform: rotate(-90deg);
    transition: opacity 0.5s ease;

    .isContinuous & {
        opacity: 1;
    }

    .isPlaying & {
        opacity: 1;
    }

    .isSwitching & {
        opacity: 1;
        transform: rotate(90deg) scaleX(-1);
    }
}

.strokeCircle {
    stroke-dashoffset: 90;
    transition-duration: 1s;
    transition-property: stroke-dashoffset;

    .isPlaying & {
        stroke-dashoffset: 0;
        transition-duration: 1s;
        transition-timing-function: linear;
    }

    .isSwitching & {
        transition-duration: 1.1s;
        transition-timing-function: ease;
    }
}

@include medium {
    .navCount {
        margin: 0 1.2rem;
    }
}
