@import 'styles/animations';
@import 'styles/breakpoints';

.scrollRoot {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;

    &.isPage {
        height: calc(100vh - 4rem);
    }
}

.root {
    position: relative;
    width: 100%;
}

.masthead {
    width: 100%;
}

.sideBarLinesWrap {
    display: none;
}

.chartWrapper {
    width: 132%;
    margin: 1.2rem 0 -2.2rem -16%;
}

.chart {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0% 8% 3%;
    color: var(--colors-cream);

    polyline {
        stroke: var(--colors-cream); //override default grid color
    }
}

@include medium {
    .scrollRoot {
        &.isPage {
            height: calc(100vh - 6rem);
        }
    }

    .root {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .inner {
        display: flex;
        width: 100%;
        max-width: var(--block-max-width-plus-padding);
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .chartWrapper {
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        width: 50%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        padding-bottom: 3%;

        .isPage & {
            height: 92vh;
            padding-bottom: 9%;
        }
    }

    .chart {
        height: auto;
        padding: 0% 8% 0% 6%;
    }

    .items {
        width: 50%;
    }
}

@include large {
    .chart {
        padding: 6% 7.5rem 0% 0%;
    }

    .items {
        padding-left: 0.8rem;
    }
}

@include xxLarge {
    .masthead {
        height: calc(100vh + 22.7rem);
    }

    .items {
        padding-left: 0;
    }

    .sideBarLinesWrap {
        position: sticky;
        z-index: 1000;
        top: 0;
        left: 0;
        display: block;
        display: flex;
        width: 0;
        height: 100vh;
        align-items: center;
        justify-content: center;

        .isPage & {
            top: -0.5rem;
            height: 92vh;
        }
    }

    .sideBarLines {
        position: absolute;
        left: 4rem;
        display: flex;
        width: 5rem;
        height: 61rem;
        align-items: center;
        justify-content: center;
    }

    .chart {
        padding: 10% 10rem 0% 1.4%;
    }

    .ageBugDrag {
        position: absolute;
        top: 0;
        width: 5.7rem;
        height: 5.7rem;
        cursor: grab;
    }
}
