@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    width: 100%;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.lastBlock {
        padding-bottom: var(--last-block-padding-bottom);
    }
}

.inner {
    position: relative;
    width: 100%;
    max-width: var(--block-max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    margin: 0 auto;

    @include rounded-corners($overflow: hidden);

    .flushTop & {
        @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
    }

    .flushBottom & {
        @include rounded-corners($overflow: hidden, $radius: 2rem 2rem 0 0);
    }

    .makers-reserve &,
    .flushTop.flushBottom & {
        border-radius: 0;
    }
}

@include large {
    .inner {
        max-height: 104rem;
    }
}
