.logo {
    position: relative;
    width: 1920px;
    height: 717px;
}

.morningstar,
.logotype {
    position: absolute;
}

.morningstar {
    top: 0;
    left: 698px;
    width: 482px;
    height: 333px;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 482 331.8' xml:space='preserve'%3E%3Cpath fill='%23fffce6' d='M255.1 34.9c13.5-6.7 21.5-1.8 21.5-1.8-3.1-7.1-3.1-15.2 0-22.4 0 0-7.9-4.9-21.5 1.8-15.6 7.8-20.5 1.8-20.5 1.8v22.4l.1.1c.5.5 5.8 5.4 20.4-1.9zM96.6 304c-12.2.7-25.6 5.9-28.6 19v.1H6.4l21.1-26.8-21.1-26.8H68c3 13.1 16.4 18.2 28.6 18.9 5.9.4 9.4.4 12.1.3h107c.8 0 2.3-.2 3-.3 5.4-1 9.7-5.3 10.7-10.8v-.4c.1-1 .2-2 .2-3.1v-10.5h-85.7c-7.4 0-18.1-4.1-21.7-12.1-4.2-9.4-6.8-19.5-7.8-29.8 6.2 2.6 33.7 10.4 77.4 6.2 13.3-1.5 26.5-4 39.4-7.4 16.4-4.5 32.6-9.9 48.3-16.2 28.2-10.7 54.1-20.6 77.4-16.4 3.4-2.7 7.2-4.8 11.4-6.2 13.5-5.3 26.9-10.7 40.3-16.1 1.4-.9 3.2-.4 4.1 1 .9 1.4.4 3.2-1 4.1-.1.1-.2.1-.3.2-3.4 1.8-31.8 17.5-33.8 18.6-2.8 2-4.5 5.2-4.5 8.7 0 4.1-2.8 7.7-6.8 8.7-6.8 2.5-9.6 9.3-12.2 15.4v.1c-1.6 4-2.9 8.2-3.8 12.4-1.1 5.2-2.8 10.2-5.1 15-5.3 10.5-13.2 13.9-26.8 13.9h-70.2v10.5c0 1.2.1 2.3.3 3.4 1 5.4 5.3 9.7 10.7 10.8h.2c.8.1 2.1.2 2.8.2h152.6c-1.3-7.5-4.6-14.4-9.6-20.1-.2-.3-.2-.6.1-.8.2-.1.4-.2.6-.1l67.3 28.6-67.3 28.6c-.3.1-.7 0-.8-.3-.1-.2-.1-.5.1-.6 4.9-5.7 8.2-12.7 9.6-20.1h-306c-2.8-.1-6.3-.1-12.2.2z'/%3E%3Cpath fill='%23fffce6' d='M202.7 207.2c-3.7.5-7.4 1-10.9 1.4-50.5 4.8-89.2-6.6-109.5-19.8C161.4 122.1 154 27.1 154 27.1L217.2 67c5.6 22.5 7.6 45.8 5.9 69 24.6-41.7 11.5-91.2 11.5-91.2L301 83.1c9.6 20.4 13.5 43.1 11.1 65.6 10.7-17 18.4-37.4 6.9-68.4 53.9 25.5 55.9 67.7 49.6 91.2-21.1-7.1-40-3.7-63.6 4.2-1.2 3-2.7 5.9-4.4 8.7 0 0-46.1 9.5-87.8.3-2.6 7.8-6 15.4-10.1 22.5z'/%3E%3C/svg%3E%0A");
        background-size: cover;
        content: '';
    }

    &::before {
        width: 100%;
        opacity: 0.2;
    }

    &::after {
        width: 100%;
        max-width: calc(100% * var(--loader-progress));
        transition: max-width 1s linear;
    }
}

.logotype {
    top: 242px;
    left: 0;
}
