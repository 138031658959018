@import 'styles/animations';

.word {
    --duration: calc(var(--fade-duration) * 1s);
    --delay: calc(var(--word-index) * var(--stagger-delay) * 1s);

    color: var(--colors-blue);
    opacity: 0.01; // LCP perf hack
    transition: opacity var(--duration) var(--delay) $easeOut;

    .reveal & {
        color: var(--colors-cream);
        opacity: 1;
    }
}
