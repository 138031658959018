@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    padding: 0 0 16%;
    color: var(--colors-cream);

    &.makersReserve {
        padding-bottom: 16rem;
    }
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12%;
    text-align: center;
}

.headlineWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headline {
    text-transform: uppercase;

    .cheddarCheeseCurds &,
    .mediumCheddar &,
    .sharpCheddar & {
        display: block;
        max-width: 24rem;
    }

    .theme--makers-reserve & {
        display: block;
        max-width: 24rem;
        text-transform: none;
    }
}

.detailsCopy {
    overflow: hidden;
    height: 0;
    transition: height $default $easeInOutCubic;
}

.copy,
.detailsCopyInner {
    max-width: 36rem;
}

.copy {
    margin: 1rem 0 0;

    .mediumCheddar &,
    .sharpCheddar &,
    .extraSharpCheddar & {
        max-width: 26rem;
    }

    .theme--makers-reserve & {
        color: var(--colors-makersReserveGoldAlt);
    }
}

.detailsCopyInnerHeight {
    width: 100%;
}

.detailsCopyInner {
    padding: 0 0 2.4rem;
    margin-top: 2rem;
}

.aspectWrapper {
    .theme--makers-reserve & {
        overflow: hidden;
        width: 200%;
        height: 67vw;
        max-height: 32rem;
        margin: 0px 0 0 -50%;
    }
}

.aspect {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 40%;
    margin: 5rem 0 2.9rem;
    transform: translateX(2rem);

    .makersReserve & {
        width: 92%;
        padding-bottom: 41%;
        transform: translateX(0rem);
    }

    .cheddarCheeseCurds & {
        padding-bottom: 53%;
        margin: 3.5rem 0 0.4rem;
        transform: translateX(0rem);
    }

    .theme--makers-reserve & {
        width: 86%;
        height: 0;
        padding-bottom: 37%;
        margin: 4rem 0 0;
        transform: translateX(8%);
    }
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ctas {
    display: flex;
    width: 109%;
    align-items: center;
    justify-content: space-between;
    margin-left: -4%;

    .theme--makers-reserve & {
        margin-top: -2rem;
    }
}

.cta {
    position: relative;
    margin: 0 0 1.7rem 0;
    cursor: pointer;
    text-transform: uppercase;

    .theme--makers-reserve & {
        text-align: right;
    }
}

.ctaInner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ctaInnerText {
    display: block;
    min-width: 10rem;
    margin-right: 1rem;
}

.ageBug {
    position: absolute;
    top: -3.7rem;
    right: 2.5rem;
    width: 5.7rem;
    height: 5.7rem;

    svg {
        width: 5.7rem;
        height: 5.7rem;
    }

    .makersReserve & {
        top: -3.5rem;
        right: -3.6rem;
    }

    .cheddarCheeseCurds & {
        top: -3.2rem;
        right: -2.1rem;
    }

    .theme--makers-reserve & {
        top: 11%;
        left: 17%;
    }
}

.unorderedList {
    padding-left: 2.3rem;
    list-style-image: url('/images/checkmark-cream.svg');
    text-align: left;

    li {
        padding-left: 1rem;
    }

    .theme--makers-reserve & {
        padding-left: 2rem;
        margin-top: 4rem;
        list-style-image: url('/images/icons/makers-reserve-list-bullet.svg');

        li {
            p {
                max-width: 38.6rem;
                padding-bottom: 1rem;
                margin: 0;
                font-size: 1.6rem;
            }

            &:after {
                position: relative;
                left: -3rem;
                display: block;
                width: calc(100% + 3rem);
                border-bottom: 2px solid var(--colors-makersReserveGold);
                content: '';
            }
        }
    }
}

.makersLogo {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 1.2rem 0;
}

.makersLogoIcon {
    position: relative;
    width: 100%;
    margin: 2.2rem 0 0;
}

.makersSharpnessYearGraphic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.4rem;
}

.makersSharpnessYear {
    padding: 0 0.9rem 0 1.4rem;
}

@include medium {
    .root {
        display: flex;
        max-width: 47rem;
        min-height: 100vh;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        padding-bottom: 14%;

        &.makersReserve {
            padding-bottom: 15%;
        }

        &.isPage {
            min-height: calc(100vh - 6rem);
        }
    }

    .aspect {
        margin: 4rem 0 2.9rem;
        transform: translateX(-2rem);

        .makersReserve & {
            transform: translateX(-3rem);
        }

        .cheddarCheeseCurds & {
            margin: 2rem 0 0.4rem;
        }

        .theme--makers-reserve & {
            margin: 3rem 0 0;
        }
    }

    .inner {
        align-items: flex-start;
        padding: 0 10%;
        text-align: left;
    }

    .headlineWrapper {
        align-items: flex-start;
    }

    .copy {
        margin: 1rem 4rem -2rem 0;
    }

    .detailsCopyInner {
        padding: 0 0 14rem;
        margin-right: 4rem;
    }

    .ageBug {
        top: -17%;
        right: 9%;

        .makersReserve & {
            top: -20%;
            right: -12%;
        }

        .theme--makers-reserve & {
            top: -1.7rem;
            left: 0.6rem;
        }
    }

    .ctas {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 0;

        .theme--makers-reserve & {
            margin-top: -5rem;
        }
    }

    .cta {
        margin: 0 2.4rem 1.7rem 0;
    }

    .makersLogo {
        align-items: flex-start;
    }

    .makersSharpnessYearGraphic {
        justify-content: flex-start;
    }
}

@include large {
    .root {
        max-width: 55rem;
    }

    .aspectWrapper {
        .theme--makers-reserve & {
            max-height: 37rem;
        }
    }

    .aspect {
        margin: 3.5rem 0 3.5rem;
    }

    .headline {
        .cheddarCheeseCurds &,
        .mediumCheddar &,
        .sharpCheddar & {
            max-width: 38rem;
        }

        .theme--makers-reserve & {
            max-width: 35rem;
        }
    }

    .copy {
        margin: 1rem 4rem -1.5rem 0;
    }

    .copy,
    .detailsCopyInner {
        max-width: 43.6rem;
    }

    .copy {
        .mediumCheddar &,
        .sharpCheddar &,
        .extraSharpCheddar & {
            max-width: 43.6rem;
        }
    }

    .ctaInnerText {
        min-width: 11.6rem;
    }

    .makersLogo {
        width: 35.4rem;
        align-items: center;
    }

    .copy,
    .detailsCopyInner {
        .makersReserve & {
            max-width: 36.6rem;
        }
    }

    .ageBug {
        .cheddarCheeseCurds & {
            top: -2.2rem;
            right: -1.1rem;
        }
    }
}

@include xLarge {
    .inner {
        padding: 0 0 0 15.4%;
    }

    .headlineWrapper {
        margin-bottom: 3rem;
    }

    .aspectWrapper {
        .theme--makers-reserve & {
            max-height: 32rem;
        }
    }

    .aspect {
        .theme--makers-reserve & {
            width: 77%;
            height: 0;
            padding-bottom: 33%;
            margin: 0;
            transform: translateX(10%);
        }
    }

    .ctas {
        .theme--makers-reserve & {
            margin-top: -3rem;
        }
    }
}

@include xxLarge {
    .inner {
        padding: 8rem 0 0 12.5%;

        .theme--makers-reserve & {
            padding: 10rem 0 0 12.5%;
        }
    }

    .headlineWrapper {
        margin-bottom: 0;
    }

    .aspect {
        width: 93%;
        padding-bottom: 35%;
        margin: 1rem 0 5.5rem;
        transform: translateX(-2.1rem);

        .makersReserve & {
            width: 96%;
            height: 0;
            padding-bottom: 43%;
            margin-top: 0;
            transform: translateX(-6rem);
        }

        .cheddarCheeseCurds & {
            width: 79%;
            padding-bottom: 41%;
            margin: 0 0 2rem;
            transform: translateX(0rem);
        }
    }

    .cta {
        margin: 0 5.9rem 1.7rem 0;

        .theme--makers-reserve & {
            margin: 0 1.9rem 1.7rem 0;
        }
    }

    .ageBug {
        display: none;
    }

    .copy {
        margin: 2rem 4rem 1rem 0;
    }
}
