@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    text-transform: uppercase;
}

.textLockup {
    position: relative;
    display: flex;
    flex-direction: column;
}

.headline,
.eyebrowWrapper {
    position: relative;
    overflow: hidden;
}

.eyebrowWrapper {
    margin-bottom: 1.9rem;
}

.eyebrow {
    margin-bottom: 0;
    animation: slide-up-out 0s $bounceLight forwards;

    .isPrev & {
        animation: slide-up-out 0.8s $bounceLight forwards;
    }

    .isActive & {
        animation: slide-up-in 0.8s $bounceLight forwards;
    }
}

.headline {
    display: flex;
    height: 9.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        line-height: 85%;
    }
}

.headlineInner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: slide-up-out 0s $bounceLight forwards;

    .isPrev & {
        animation: slide-up-out 0.8s $bounceLight forwards;
    }

    .isActive & {
        animation: slide-up-in 0.8s $bounceLight forwards;
    }
}

@keyframes slide-up-out {
    0% {
        translate: 0 0;
    }

    100% {
        translate: 0 -110%;
    }
}

@keyframes slide-up-in {
    0% {
        translate: 0 110%;
    }

    100% {
        translate: 0 0;
    }
}

@include large {
    .eyebrowWrapper {
        margin-bottom: 1.6rem;
    }

    .headline {
        height: 12.6rem;
    }
}

@include xLarge {
    .headline {
        height: 15.7rem;
    }
}

@include xxLarge {
    .headline {
        height: 20.9rem;
    }
}
