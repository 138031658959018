@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

.blockHero {
    // Set by JS on resize:
    // --morningstar-scale
    // --full-scale
    // --hero-height
    // --vertical-offset

    // Set by JS on scroll:
    // --cover-progress
    // --video-progress
    // --logo-progress
    // --logo-opacity-progress
    // --jalousie-progress

    --morningstar-origin: 939px 165px;
    --morningstar-pos: translate3d(calc(-50% + 22px), calc(-50% + 193px), 0);
    --full-origin: 939px 358px;
    --full-pos: translate3d(
        calc(-50% + 22px),
        calc(-50% + var(--vertical-offset)),
        0
    );
    --logotype-scale: calc(var(--header-logo-width) / 1920);
    --logotype-height: calc(465.5px * var(--logotype-scale));
    --cover-center: translate3d(-50%, calc(-50% + var(--vertical-offset)), 0);
    --cover-all: translate3d(-50%, calc(-50% + var(--hero-height) / 2), 0);
    --video-start: translate3d(0, calc(40% + var(--vertical-offset)), 0);

    position: relative;
    overflow: hidden;
    height: calc(100vh - 108px);
    margin-top: calc(var(--header-height) * -1);
    background-color: var(--colors-blue);

    &.isInView {
        &::before {
            position: absolute;
            z-index: 1; // above video
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 12.8rem;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 30%) 0.78%,
                rgba(217, 217, 217, 6%) 77.34%,
                rgba(217, 217, 217, 0%) 100%
            );
            content: '';
        }
    }
}

.videoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cover {
    position: absolute;
    z-index: 1; // above scrim
    top: 50%;
    left: 50%;
    width: 3260px;
    height: 3260px;
    transform: var(--cover-all);

    .blockHero-reveal & {
        transform: var(--cover-center);
        transition: transform 0.5s 0.5s $easeOutCubic;
    }

    .blockHero-scroll & {
        --cover-scroll-height: calc(
            954px + var(--vertical-offset) + var(--hero-height) / 2
        );
        --cover-scroll-pos: calc(
            var(--vertical-offset) * -1 + var(--cover-scroll-height) *
                var(--cover-progress)
        );

        transform: translate3d(-50%, calc(-50% - var(--cover-scroll-pos)), 0);
        transition: none;
    }
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: var(--video-start);
    visibility: hidden;

    .blockHero-reveal & {
        visibility: visible;
    }

    .blockHero-scroll & {
        --video-progress-inverse: calc(1 - var(--video-progress));
        --video-scroll-height: calc(40% + var(--vertical-offset));
        --video-scroll-pos: calc(
            var(--video-progress-inverse) * var(--video-scroll-height)
        );

        transform: translate3d(0, var(--video-scroll-pos), 0);
        visibility: visible;
    }
}

.logoContainer {
    position: absolute;
    z-index: 1; // above scrim
    top: 0.3rem; // match logo with morningstar at the top of header
    left: 0;
    width: 100%;
    height: 100%;
}

.logoWrapper {
    position: relative;
    top: 50%;
    left: 50%;
    width: 1920px;
    height: 717px;
    backface-visibility: hidden;
    transform: var(--morningstar-pos) scale(0);
    transform-origin: var(--morningstar-origin);
    visibility: var(--logo-visibility);
    will-change: transform, transform-origin;

    .logo {
        .morningStar {
            opacity: 1;
        }

        .logoType {
            opacity: 0;
        }
    }

    .tagline {
        margin-top: calc(2.5rem / var(--full-scale));
        color: var(--colors-cream);
        font-size: calc(3.6rem / var(--full-scale));
        text-align: center;
        text-transform: uppercase;

        .taglineContent {
            > p {
                margin: 0;
            }
        }
    }

    .blockHero-loader & {
        transform: var(--morningstar-pos) scale(var(--morningstar-scale));
        transform-origin: var(--morningstar-origin);
        transition: transform 0.5s 0.25s $easeOut,
            transform-origin 0.5s 0.25s $easeOut;
    }

    .blockHero-reveal & {
        transform: var(--full-pos) scale(var(--full-scale));
        transform-origin: var(--full-origin);
        transition: transform 1s $easeInOutQuart,
            transform-origin 1s $easeInOutQuart;

        .logo {
            .logoType {
                opacity: 1;
                transition: opacity 1s $linear;
            }
        }
    }

    .blockHero-scroll & {
        // Fade out morningstar and tagline
        --logo-opacity: calc(1 - var(--logo-opacity-progress));

        // Transition transform-origin from full logo center to logotype center
        --logo-origin-x: calc(
            939px + calc(960px - 939px) * var(--logo-progress)
        );
        --logo-origin-y: calc(
            358px + calc(479px - 358px) * var(--logo-progress)
        );

        // Transition logotype position and scale
        --logo-y-start: calc(-50% + var(--vertical-offset));
        --logo-y-adjust: 107px;
        --logo-y-end: calc(
            -50% - var(--logo-y-adjust) + calc(
                    var(--header-height) - var(--logotype-height)
                ) / 2 - var(--hero-height) / 2
        );
        --logo-y-pos: calc(
            var(--logo-y-start) + calc(var(--logo-y-end) - var(--logo-y-start)) *
                var(--logo-progress)
        );
        --logo-x-offset: calc(22px * calc(1 - var(--logo-progress)));
        --logo-scale: calc(
            var(--full-scale) + calc(var(--logotype-scale) - var(--full-scale)) *
                var(--logo-progress)
        );

        transform: translate3d(
                calc(-50% + var(--logo-x-offset)),
                var(--logo-y-pos),
                0
            )
            scale(var(--logo-scale));
        transform-origin: var(--logo-origin-x) var(--logo-origin-y);
        transition: none;

        .logo {
            .logoType {
                opacity: 1;
                transition: none;
            }
        }

        .tagline {
            opacity: var(--logo-opacity);
        }
    }
}

.scrollIndicator {
    position: absolute;
    bottom: -11.2rem;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%) scale(0);

    .blockHero-reveal & {
        opacity: 1;
        transform: translateX(-50%) scale(1);
        transition: opacity 0.75s 1.2s $linear, transform 0.75s 1.2s $easeOut;
    }

    .blockHero-scroll & {
        --logo-opacity: calc(1 - var(--logo-opacity-progress));

        opacity: var(--logo-opacity);
        transform: translateX(-50%) scale(1);
        transition: none;
    }
}

.secondaryHero {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 var(--grid-padding-side) 0;
    color: var(--colors-cream);
    opacity: var(--jalousie-progress, 0);

    .eyebrow {
        margin-bottom: 1.2rem;
        opacity: 0;
        text-align: center;
        transform: translate3d(0, calc(var(--hero-height) / 3), 0);
        transition: transform 0.5s 0.3s $easeIn, opacity 0.5s 0.3s $linear;

        &.reveal {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: transform 0.5s $easeOut, opacity 0.5s $linear;
        }
    }

    .headline {
        text-transform: uppercase;
    }
}

@include medium {
    .blockHero {
        height: 100vh;
    }

    .secondaryHero {
        .eyebrow {
            text-align: left;
        }
    }

    .logoWrapper {
        .tagline {
            margin-top: 0;
            font-size: calc(4.8rem / var(--full-scale));

            .taglineContent {
                > p {
                    display: inline;
                }
            }
        }
    }
}

@include large {
    .logoWrapper {
        .blockHero-scroll & {
            --logo-y-adjust: 102px;
        }
    }

    .secondaryHero {
        .eyebrow {
            margin-bottom: 3.5rem;
        }
    }
}

@include xxxLarge {
    .secondaryHero {
        padding: 0 24rem;
    }
}
