@import 'styles/animations';
@import 'styles/breakpoints';

.blockRoot {
    margin-top: -4.8rem;
    pointer-events: none;

    &.isExtendedTop {
        margin-top: 0;
    }
}

.root {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    color: var(--colors-blue);
}

.inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 4.8rem;

    .flushTop & {
        margin-top: calc(var(--block-padding-side) * -1);
    }

    .flushBottom & {
        margin-bottom: calc(var(--block-padding-side) * -1);
    }
}

.slides {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    pointer-events: none;
    text-align: center;

    &::before {
        position: absolute;
        top: 4.8rem;
        left: 2.4rem;
        width: calc(100% - 4.8rem);
        height: auto;
        border-radius: 2rem;
        aspect-ratio: 1 / 1;
        background-color: var(--background-color);
        content: '';
        transition: background-color 0.8s $easeOutCubic;
    }
}

.content {
    position: relative;
    display: flex;
    width: 100%;
    height: 34rem;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 4.8rem 0;
    pointer-events: auto;
}

.text {
    position: relative;
    width: 100%;
    height: 15.1rem;
}

.buttons {
    position: relative;
    width: 100%;
    height: 4rem;
}

.button {
    position: absolute;
    width: 100%;
}

.buttonTransition {
    position: absolute;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.4s $easeOutQuad, visibility 0.4s $easeOutQuad 0.4s;
    visibility: hidden;

    &.isActive {
        opacity: 1;
        transition: opacity 0.4s $easeOutQuad 0.4s,
            visibility 0.4s $easeOutQuad 0.4s;
        visibility: visible;
    }
}

.nav {
    position: absolute;
    bottom: 4.8rem;
    opacity: 0;
    translate: 0 2rem;

    .isInView & {
        animation: fade-up $default $easeOutQuad 0.3s forwards;
    }
}

@keyframes fade-up {
    100% {
        opacity: 1;
        translate: 0 0;
    }
}

@include medium {
    .blockRoot {
        margin-top: calc(var(--block-padding-side) * -1);

        &.isExtendedTop {
            margin-top: 0;
        }
    }

    .root {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .inner {
        display: flex;
        max-width: var(--block-max-width-plus-padding);
        align-items: center;
        justify-content: center;
        padding: var(--block-padding-side);
        padding-top: calc(var(--block-padding-side) * 2);

        &::before {
            position: absolute;
            top: calc(var(--block-padding-side) * 2);
            left: var(--block-padding-side);
            width: calc(100% - var(--block-padding-side) * 2);
            height: auto;
            border-radius: 3rem;
            aspect-ratio: 1248 / 600;
            background-color: var(--background-color);
            content: '';
            transition: background-color 0.8s $easeOutCubic;
        }
    }

    .slides {
        z-index: unset;
        aspect-ratio: 624 / 600;

        &::before {
            display: none;
        }
    }

    .content {
        height: 100%;
        justify-content: center;
        padding: 0;
        aspect-ratio: 624 / 600;
    }

    .nav {
        position: relative;
        bottom: auto;
        margin-top: 2.4rem;
    }
}

@include large {
    .text {
        height: 17.9rem;
    }

    .buttons {
        height: 5rem;
    }
}

@include xLarge {
    .text {
        height: 21.1rem;
    }
}

@include xxLarge {
    .text {
        height: 26.1rem;
    }
}
