@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.root {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--block-padding);

    &.displayTheme {
        padding: 0;
    }

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    text-align: center;

    .displayTheme & {
        width: 100%;
        max-width: none;
    }
}

.eyebrowBlock {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--block-padding-end);
    color: var(--colors-blue);
}

.contentWrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    color: var(--colors-cream);

    @include rounded-corners($overflow: hidden);

    .flushTop & {
        @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
    }

    .flushTop.flushBottom &,
    .displayTheme & {
        border-radius: 0;
    }
}

.innerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: var(--innerWrapper-width, 100%);
    height: var(--innerWrapper-height, 50%);
    align-items: center;
    justify-content: center;

    .hasCTA & {
        height: var(--innerWrapper-hasCTA-height, 53%);
    }

    .textOnRight & {
        right: 0;
        left: auto;
    }
}

.innerWrapperInner {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.textBlock {
    z-index: 10;
    top: 0;
    left: var(--textBlock-left, 0);
    display: flex;
    width: 100%;
    max-width: var(--textBlock-max-width, 32rem);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: var(--textblock-padding-top, 4rem);
    color: var(--colors-cream);
}

.logoBlock {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: center;

    .logoWrapper {
        position: relative;
        width: 10rem;
        height: 2rem;
    }
}

.headlineWrapper {
    margin-bottom: 2rem;
    text-align: center;

    .headline {
        max-width: 26rem;
        color: var(--headline-color);
        text-transform: uppercase;
    }
}

.eyebrowWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.heroImgBlock {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 194%;

    .displayTheme & {
        padding-bottom: 175%;
    }

    .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: none;
        height: 100%;
        object-fit: cover;
    }
}

.innerEyebrow {
    h3 {
        text-transform: none;
    }
}

.mainIcon {
    position: relative;
    width: var(--main-icon-width, 4.1rem);
    height: var(--main-icon-height, 2.7rem);
    margin-bottom: 2rem;
}

.featuredTheme,
.displayTheme {
    .logoWrapper {
        width: 20rem;
        height: 3rem;
    }
}

.eyebrow {
    margin-bottom: 1rem;
    color: var(--eyebrow-color);
    text-transform: uppercase;

    .displayTheme & {
        margin-bottom: 2rem;
    }
}

.buttonWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    &.multiButton {
        flex-direction: row;

        > * {
            margin: 0 0.6rem;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@include medium {
    .inner {
        --innerWrapper-height: 100%;
        --innerWrapper-hasCTA-height: 100%;
        --innerWrapper-width: 50%;
        --textblock-padding-top: 0rem;
    }

    .heroImgBlock {
        padding-bottom: 56.25%;

        .displayTheme & {
            padding-bottom: 50%;
        }
    }
}

@include large {
    .inner {
        --textBlock-max-width: 41rem;
        --main-icon-width: 6.4rem;
        --main-icon-height: 4.3rem;
    }

    .heroImgBlock {
        padding-bottom: 50%;
    }
}

@include xLarge {
    .inner {
        --textBlock-max-width: 45rem;

        .displayTheme & {
            --textBlock-max-width: 70rem;
        }
    }
}
