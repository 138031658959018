@import 'styles/breakpoints';
@import 'styles/mixins';

.root {
    position: relative;
    width: 100%;
}

.aspect {
    position: relative;
    width: 100%;
    height: var(--aspect-height, calc(100vh - 10rem));
    min-height: var(--aspect-min-height, 40rem);

    @media (min-height: 620px) {
        --aspect-height: calc(100vh - 16rem);
    }

    .theme--makers-reserve-border & {
        height: var(--aspect-height, calc(80vh - 10rem));
        min-height: var(--aspect-min-height, 40rem);

        @media (min-height: 620px) {
            --aspect-height: calc(80vh - 16rem);
        }
    }

    .theme--midpage & {
        height: 0;
        padding: var(--aspect-midpage-padding, 0 0 125.333%);
    }

    .theme--fullHeight & {
        height: 100vh;
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    video.small-hide {
        display: none;
    }
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;

    .imageLargeVerticalAlignment-center & {
        object-position: center;
    }
}

.textContainer {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-bottom: var(--block-padding-end);
    gap: 2.4rem;
    transform: translate(-50%, -50%);

    .headlineAlignLeft & {
        top: calc(25% + 2.4rem);
    }

    .theme--makers-reserve & {
        display: flex;
        width: 80%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 5rem;
        text-align: center;
    }

    .headlinePortraitAlignment-top & {
        top: 3.8rem;
        transform: translate(-50%, 0%);
    }
}

.eyebrow {
    text-transform: uppercase;
}

.description {
    width: 70%;
    max-width: 55rem;
    text-align: center;
}

.calloutWrapper {
    width: 100%;
    padding: 0 var(--block-padding-side) var(--block-padding-end)
        var(--block-padding-side);
    margin: var(--calloutWrapper-margin, -4.8rem 0 0 0);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.midpageCallout {
    position: relative;
    width: 100%;

    &.banner {
        height: var(--calloutWrapper-banner-height, auto);
    }
}

.headline {
    .theme--makers-reserve & {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        display: flex;
        width: 80%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 5rem;
        text-align: center;
        transform: translate(-50%, -50%);

        > div {
            max-width: none;
        }
    }
}

.makersReserveBorder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 3rem;
}

@mixin svg-portrait-alignment-top {
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0%);
}

@mixin svg-landscape-alignment-left {
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%) translateX(4.8rem);
}

@mixin svg-landscape-alignment-right {
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%) translateX(-4.8rem);
}

.svgLockup {
    --width: 64vw;
    --min-width: 30rem;
    --max-width: 38rem;

    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    display: flex;
    width: var(--width);
    min-width: var(--min-width);
    max-width: var(--max-width);
    height: calc(var(--svg-ratio) * var(--width));
    min-height: calc(var(--svg-ratio) * var(--min-width));
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-top: -5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translate(-50%, -50%);

    picture,
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .svgPortraitAlignment-top & {
        @include svg-portrait-alignment-top;
    }

    .description {
        margin-top: 2.4rem;
    }
}

.makersReserveBorderInner {
    position: relative;
    width: 100%;
    height: 100%;
}

.buttonWrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 2.4rem;
}

@include landscape {
    .textContainer {
        padding-bottom: 0;

        .headlineLandscapeAlignment-left & {
            top: 50%;
            left: 0;
            width: 50%;
            transform: translate(0%, -50%);
        }

        .headlineLandscapeAlignment-right & {
            top: 50%;
            left: 50%;
            width: 50%;
            transform: translate(0%, -50%);
        }

        .headlineLandscapeAlignment-center & {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .svgLockup {
        .svgLandscapeAlignment-left & {
            @include svg-landscape-alignment-left;
        }

        .svgLandscapeAlignment-right & {
            @include svg-landscape-alignment-right;
        }
    }
}

@include medium {
    .aspect {
        --aspect-height: calc(100vh - 6rem);
        --aspect-min-height: 60rem;
        --aspect-midpage-padding: 0 0 43.333%;

        .theme--makers-reserve-border & {
            height: calc(100vh - 6rem);
            min-height: 60rem;
        }

        video.large-hide {
            display: none;
        }

        video.videoLarge {
            display: block;
        }
    }

    .calloutWrapper {
        --calloutWrapper-margin: -7.4rem 0 0 0;
    }

    .textContainer {
        padding-bottom: 10rem;

        .theme--midpage & {
            padding-bottom: 0;
        }

        .headlineAlignLeft & {
            top: 50%;
            left: 25%;
        }

        .makers-reserve & {
            max-width: none;
            padding-bottom: 14rem;
        }
    }

    .svgLockup {
        .svgPortraitAlignment-top & {
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .svgLandscapeAlignment-left & {
            @include svg-landscape-alignment-left;
        }

        .svgLandscapeAlignment-right & {
            @include svg-landscape-alignment-right;
        }
    }

    .makersReserveBorder {
        padding: 2rem;
    }
}

@include large {
    .aspect {
        --aspect-height: calc(100vh - 8rem);
    }

    .calloutWrapper {
        --calloutWrapper-margin: -9.6rem 0 0 0;
    }

    .eyebrow {
        font-size: 2.4rem;
    }

    .textContainer {
        padding-bottom: 14rem;

        .theme--midpage & {
            padding-bottom: 0;
        }
    }

    .svgLockup {
        --width: 37vw;
        --max-width: 48rem;

        .svgLandscapeAlignment-left & {
            transform: translate(-50%, -50%);
        }

        .svgLandscapeAlignment-right & {
            transform: translate(-50%, -50%);
        }

        .description {
            margin-top: 4rem;
        }
    }

    .buttonWrapper {
        margin-top: 4rem;
    }
}

@include xxLarge {
    .aspect {
        --aspect-min-height: 80rem;

        .theme--midpage & {
            --aspect-height: calc(90vh - 8rem);
            --aspect-min-height: 70rem;
        }
    }

    .svgLockup {
        max-width: 69.2rem;
    }
}

@include portrait {
    .svgLockup {
        .svgPortraitAlignment-top & {
            @include svg-portrait-alignment-top;
        }
    }
}
