@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

.root {
    --margin: 2.4rem;

    position: relative;
    width: 100%;
    padding-bottom: var(--margin);
    border-bottom: 1px solid rgba(color('blue'), 0.2);
}

.headline {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: var(--margin) 0 0;
    border: none;
    background-color: transparent;
    color: var(--colors-blue);
    gap: 0 1rem;
    text-align: left;
    text-transform: uppercase;

    .isActive & {
        pointer-events: none;
    }

    @media (hover: hover) {
        &:hover {
            .headlineIcon {
                background-color: var(--colors-blue);
            }
        }
    }
}

.headlineIcon {
    position: relative;
    display: flex;
    width: 2.8rem;
    height: 2.8rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #41b6e6;
    transition: transform $default $easeOutCubic,
        background-color $default $easeOutCubic;

    .isActive & {
        transform: rotate(180deg);
    }
}

.headlineIconChevron {
    width: 1.2rem;
    height: 0.8rem;
    color: var(--colors-white);
}

.copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1rem;
    gap: var(--margin);
    opacity: 0;
    transition: opacity $default $easeOutCubic;

    p {
        margin: 0;
    }

    .isActive & {
        opacity: 1;
    }
}

.summary,
.content {
    position: relative;
    display: block;
    overflow: hidden;
}

.content {
    .isMounted & {
        transition: height $default $easeOutCubic;
    }
}

@include large {
    .root {
        --margin: 3rem;

        &:first-child {
            .headline {
                padding-top: 0;
            }
        }
    }

    .copy {
        padding-top: 1.2rem;
    }
}
