@import 'styles/animations';
@import 'styles/breakpoints';

.root {
    position: relative;
    overflow: hidden;
    border-radius: 2rem;

    // animation on load trick, first animation hides all items instantly
    animation: fade-out 0.001s forwards;

    // then we update the animation after mount, it does not reset the animation (trick)
    // we've now added a delay to the animation without causing a visual change (neato)
    &.isMounted {
        // force the elements to remain visible while the active class animates in
        // this creates a true crossfade between the items
        animation: fade-out 0.001s forwards $default;
    }

    // ensure previous item is always underneath active item
    &.isMounted.isPrevious {
        z-index: 1;
    }

    // crossfade active item on top of previous item
    &.isMounted.isActive {
        z-index: 2;
        animation: fade-in $default forwards;
    }
}

.root,
.media,
.videoPlayButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.videoPlayButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    margin: 0;
    background: none;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
