@import 'styles/breakpoints';
@import 'styles/mixins';

.blockHeader {
    @include flex-center;

    flex-direction: column;
    text-align: center;
    background-color: var(--colors-blueDark);
    color: var(--colors-cream);
    padding: var(--header-padding, 4.8rem);
}

.headline {
    text-transform: uppercase;
    max-width: var(--headline-max-width, 30rem);
    margin-bottom: var(--headline-margin-bottom, 2rem);
}

.subheadline {
    margin-top: var(--subheadline-margin-top, 3.5rem);
}

@include large {
    .blockHeder {
        --header-padding: 9.6rem 0;
    }

    .headline {
        --headline-max-width: 60rem;
        --headline-margin-bottom: 3rem;
    }

    .subheadline {
        --subheadline-margin-top: 6rem;

        max-width: 70rem;
    }
}
