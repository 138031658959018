@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/colors';

.filter {
    @include flex-center;

    width: 100%;
    flex-direction: column;
    padding-bottom: var(--filter-padding-bottom, 3.2rem);
    margin-bottom: var(--filter-margin-bottom, 0);
    background-color: transparent;
    color: var(--colors-blue);
}

.copy {
    max-width: 70rem;
    margin-top: 2.4rem;
    text-align: center;

    &.theme--foodService & {
        @include smallOnly {
            padding: 0 2.4rem;
        }
    }
}

.theme--all,
.theme--foodService {
    --filter-margin-bottom: 1rem;

    background-color: var(--colors-blue);
    color: var(--colors-cream);

    .filterSelectElement {
        color: var(--colors-blue);
    }

    .filterMasthead {
        background-color: var(--colors-blue);
        color: var(--colors-cream);
    }

    .filterSelectWrapper {
        border-bottom: 1px solid rgba(color('white'), 0.25);
    }

    .search {
        border-bottom: 0.2rem solid var(--colors-cream);
        margin-bottom: 1.8rem;
        color: var(--colors-cream);

        input {
            color: var(--colors-cream);
            font-weight: 500;
            text-indent: -0.1rem;

            &:focus::placeholder {
                color: var(--colors-blue);
            }

            &::placeholder {
                color: var(--colors-cream);
            }
        }
    }

    .clear {
        border-bottom: 1px solid var(--colors-cream);
        color: var(--colors-cream);
        line-height: 1.44rem;
        text-transform: uppercase;
    }
}

.theme--foodService {
    @include smallOnly {
        padding-top: 2rem;
    }
}

.filterSelectWrapper {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(color('blue'), 0.25);
}

.filterLabel {
    position: absolute;
    display: none;
    text-transform: uppercase;
}

.filterSelect {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
}

.filterSelectElement {
    width: 100%;
    padding: 1.1rem 0;
    border: 0;
    appearance: none;
    cursor: pointer;
    opacity: 0;
}

.filterSelectIcon {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1.1rem;
    height: 0.7rem;
    line-height: 2;
    pointer-events: none;
    translate: 0 -50%;
}

.filterSelectDisplay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-right: 2.1rem;
    text-align: left;
}

.filterMasthead {
    @include flex-center;

    flex-direction: column;
    padding-top: 4rem;
    color: var(--colors-blue);

    &.theme--all {
        height: 20rem;
        padding-top: 0;
    }

    &.theme--all,
    &.theme--foodService {
        background-color: var(--colors-blue);
        color: var(--colors-cream);
    }

    &.theme--foodService {
        margin-top: 6rem;
    }
}

.filterMastheadEyebrow {
    margin-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
}

.filterMastheadHeadline {
    text-align: center;
    text-transform: uppercase;
}

.filters,
.search,
.clearWrapper {
    grid-column: var(--productImage-grid-column, span 4);
}

.clearWrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2.4rem;

    &.isHidden {
        opacity: 0;
        pointer-events: none;
    }
}

.filters {
    display: flex;
    flex-direction: column;
}

.search {
    position: relative;
    padding: 0;
    border-bottom: 0.2rem solid var(--colors-blue);
    margin-bottom: 3rem;

    label {
        width: 100%;
    }

    input {
        width: 100%;

        // Avoid overlap with search icon
        padding-right: 2rem;
        border: none;
        background: transparent;
        color: var(--colors-blue);
        line-height: 200%;

        &:focus {
            outline: none;

            &::placeholder {
                color: var(--colors-cream);
            }
        }

        &::placeholder {
            color: var(--colors-blue);
        }
    }
}

.searchIcon {
    position: absolute;
    top: 1.1rem;
    right: 0;
    width: 1.5rem;
    height: 1.6rem;

    &.clickable {
        cursor: pointer;
    }
}

.block {
    width: 100%;
    max-width: var(--block-max-width-plus-padding);
    padding: 0 2.4rem;
}

@include medium {
    .filter {
        &.theme--all,
        &.theme--foodService {
            --filter-margin-bottom: 2.4rem;
        }
    }

    .filters,
    .clearWrapper,
    .search {
        grid-column: 2 / span 6;
    }

    .block {
        display: flex;
        justify-content: space-between;
        padding: 0 var(--block-padding-side);

        .search {
            width: 100%;
            max-width: 30rem;
        }
    }
}

@include large {
    .filter {
        --filter-padding-bottom: 6.4rem;

        .search {
            margin-bottom: 0;
        }
    }

    .filterMasthead {
        height: 29rem;
        padding-top: 4rem;

        &.theme--all {
            padding-top: 4rem;
        }
    }

    .clearWrapper {
        grid-column: 12 / span 1;
        grid-row: 1;
    }

    .search {
        grid-column: var(--productImage-grid-column, span 3);
        grid-row: 2;
    }

    .filters {
        flex-direction: row;
        justify-content: flex-end;
        grid-column: var(--productImage-grid-column, span 9);
        grid-row: 2;
    }

    .filterSelectWrapper {
        width: auto;
        justify-content: flex-start;
        margin-left: 2.4rem;

        &:last-child {
            margin-right: 0;
        }
    }

    .filterLabel {
        position: relative;

        .hasSelection & {
            display: none;
        }
    }

    .filterSelectIcon {
        position: relative;
        margin-left: 1rem;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .filterSelectDisplay {
        position: relative;
        top: auto;
        left: auto;
        width: auto;
        height: auto;
        padding-right: 0;
        text-align: left;
    }

    .filterSelect {
        position: static;
        width: 100%;
        min-width: 7em;
        justify-content: space-between;
    }

    .filterSelectElement {
        position: absolute;
        left: 0;
        width: 100%;
    }
}

@include xxLarge {
    .search {
        grid-column: span 3;
    }

    .filters {
        grid-column: 5 / span 8;
    }

    .filterSelectWrapper {
        margin-left: 3.6rem;
    }

    .block {
        .search {
            max-width: 39rem;
        }
    }
}

@include xxxLarge {
    .search {
        grid-column: 2 / span 3;
    }

    .clearWrapper {
        grid-column: 10 / span 2;
    }

    .filters {
        flex-direction: row;
        justify-content: flex-end;
        grid-column: 7 / span 5;
    }
}
