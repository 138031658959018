@import 'styles/breakpoints';
@import 'styles/colors';

.root {
    text-align: center;
    padding: var(
        --root-padding,
        var(--block-padding-end) var(--block-padding-side)
    );
    color: var(--colors-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: var(--block-max-width);
    margin: 0 auto;

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }
}

.iframeWrapper {
    padding: 4.8rem 0;
    background-color: var(--colors-white);
    max-width: var(--frame-wrapper-max-width, none);

    &.richTextSpacer {
        margin-top: 4.8rem;
    }
}

.richText {
    padding-top: var(--richText-padding-top, 1rem);
    text-align: left;
    display: flex;
    flex-direction: column;
    max-width: 80%;
}

.richTextHeading {
    margin-bottom: var(--rich-text-heading-margin-bottom, 2rem);
}

.richTextUnorderedList {
    padding-left: 2rem;

    li {
        p {
            margin-bottom: 0.5rem;
        }
    }
}

@include medium {
    .root {
        --root-padding: 4.8rem var(--block-padding-side);
        --rich-text-heading-margin-bottom: 3rem;

        &.flushTop {
            padding-top: 0;
        }

        &.flushBottom {
            padding-bottom: 0;
        }
    }
}

@include xLarge {
    .root {
        --frame-wrapper-max-width: 103.2rem;

        &.flushTop {
            padding-top: 0;
        }

        &.flushBottom {
            padding-bottom: 0;
        }
    }
}
