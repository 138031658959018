@import 'styles/animations';
@import 'styles/colors';
@import 'styles/breakpoints';

.icon {
    position: relative;
    width: 2.7rem;
    height: 2.7rem;
    margin-left: 2rem;
    transform: rotate(90deg);
    transition: transform $default $easeInOutCubic;
}

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;
}

.inner {
    width: 100%;
    max-width: 63.6rem;
    padding: var(--block-padding-end) 0;

    table {
        width: 100%;
        border-collapse: collapse;

        * {
            box-sizing: border-box;
        }
    }

    ul {
        padding: 0;
        list-style: none;
    }

    td,
    th {
        padding: 1rem 0 0.3rem;
        margin: 0;
        font-weight: 500;
        text-align: left;

        .theme--makers-reserve & {
            border-bottom: 1px solid rgba(color('makersReserveGold'), 0.25);
        }
    }

    thead {
        border-bottom: 1px solid rgba(color('blue'), 0.25);
        font-size: 1.4rem;

        th {
            padding: 0 0 0.5rem;
            margin: 0;
        }
    }
}

.line {
    border-bottom: 1px solid rgba(color('blue'), 0.25);
}

.pdv {
    display: block;
    margin: 1rem 0 0;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
}

.allergensHeading {
    display: block;
    margin: 4.3rem 0 0;
    text-transform: uppercase;
}

.allergensBody {
    display: block;
    margin: 0.8rem 0 0;
}

.indent {
    td:first-child {
        padding-left: 1.2rem;
    }
}

.separator {
    border-bottom: 2px solid var(--colors-blue);

    .theme--makers-reserve & {
        border-bottom: 2px solid var(--colors-makersReserveGold);
    }
}

.servings {
    margin-top: 2rem;
}

@include large {
    .inner {
        padding-top: 4.8rem;
    }
}
