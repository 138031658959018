@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/mixins';

.root {
    position: relative;
    width: 100%;
    padding: var(--block-padding);

    &.flushTop {
        padding-top: 0;
    }

    &.flushBottom {
        padding-bottom: 0;
    }

    &.lastBlock {
        padding-bottom: var(--last-block-padding-bottom);
    }
}

.inner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: var(--block-max-width);
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    text-align: center;

    @include rounded-corners($overflow: hidden);

    .flushTop & {
        @include rounded-corners($overflow: hidden, $radius: 0 0 2rem 2rem);
    }

    .flushBottom & {
        @include rounded-corners($overflow: hidden, $radius: 2rem 2rem 0 0);
    }

    .flushTop.flushBottom & {
        border-radius: 0;
    }
}

.slides {
    width: 100%;
}

.slide {
    position: absolute;
    top: 0;
    width: 100%;
    background: #000;
    opacity: 0;
    pointer-events: none;

    &-active {
        position: relative;
        animation: fade-in 0.6s $easeOutQuad 0.1s forwards;
        pointer-events: all;
    }

    &-prev {
        animation: fade-out 0.5s $easeOutQuad forwards;
    }
}

.paginationButton {
    color: var(--colors-blue);

    .theme--makers-reserve & {
        color: var(--colors-makersReserveOffBlack);
    }
}

.paginationControls {
    color: var(--colors-cream);
    opacity: 0;
    transition: opacity $fast;

    .paginationControlsInner {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
    }

    &--show {
        opacity: 1;
        transition: opacity $slow $fast;
    }

    .theme--makers-reserve & {
        color: var(--colors-makersReserveOffBlack);
    }
}

.paginationCount {
    min-width: 4rem;
    margin: 0 1rem;
    text-align: center;
}

@include large {
    .slide {
        position: absolute;
        height: 100%;
    }

    .paginationControls {
        position: absolute;
        z-index: 5;
        right: 2rem;
        display: flex;
        width: 9%;
        height: 100%;
        align-items: center;
        justify-content: center;
        margin: 0;

        .paginationControlsInner {
            flex-direction: column;
        }
    }

    .buttonPrev {
        order: 3;
    }

    .paginationCount {
        order: 2;
        margin: 1rem 0;
    }

    .buttonNext {
        order: 1;
    }

    .slides {
        min-height: 62.4rem;
        padding-top: 50%;
    }
}

@keyframes fade-in {
    0% {
        z-index: 0;
        opacity: 0;
    }

    100% {
        z-index: 1;
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        z-index: 1;
        opacity: 1;
    }

    100% {
        z-index: 0;
        opacity: 0;
    }
}
